import React, { Component } from 'react';
import Modal from './common/Modal';

import ImageJpeg from './../../resources/images/instaahorrazos.jpg';

class ShowModalInHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: props.active ? 'is-active' : '',
    };
  }

  onClickCloseModel = () => {
    if (this.state.isActive !== '') {
      this.setState({ isActive: '' });
    }
  };

  render() {
    const { isActive } = this.state;

    const body = (
      <p className="image is-4by3">
        <img src={ImageJpeg} alt="¡Insta Ahorrazos!" />
      </p>
    );

    return (
      <Modal
        isActive={isActive}
        body={body}
        onClickCloseModel={this.onClickCloseModel}
      />
    );
  }
}

export default ShowModalInHome;
